img{
    height: 30px;
    width:50 px;
}

.checkbutton{
    height: 10px;
}

.isManager{
    display: flex; 
    flex-direction: row; 
    width: 100%;
    /* padding: 100px; */
    margin: 10px;
}
.is-manager-question{
    width: auto;
    /* padding: 100px; */
    /* margin: 100px; */
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
}
.div-site-manager-row{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}
.site-manager{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}
.isSiteManager{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: blueviolet; */
    border-style: solid;
}

.area-manager-site-selection{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}

.area-manager-area-selection{
    display: flex; 
    flex-direction: row; 
    width: 500px; 
    /* background-color: blue; */
}

.div-area-manager-row{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}

.isAreaManager{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: greenyellow; */
    border-style: solid;
}

.isLineManager{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: indianred; */
    border-style: solid;
}

.isManagerContent{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.form-style-2{
    width: 100%;
}
.roles-section{
    width: 100%;
}


.dropdown-selection{
    width: 400px;
}


.isOperator{
    display: flex; 
    flex-direction: row; 
    width: 100%;
    /* padding: 100px; */
    margin: 10px;
}
.is-operator-question{
    width: auto;
    /* padding: 100px; */
    /* margin: 100px; */
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
}
.div-site-operator-row{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}
.site-operator{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}
.isSiteOperator{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: blueviolet; */
    border-style: solid;
}

.area-operator-site-selection{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}

.area-operator-area-selection{
    display: flex; 
    flex-direction: row; 
    width: 500px; 
    /* background-color: blue; */
}

.div-area-operator-row{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}

.isAreaOperator{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: greenyellow; */
    border-style: solid;
}

.isLineOperator{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: indianred; */
    border-style: solid;
}

.isOperatorContent{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}








.isViewer{
    display: flex; 
    flex-direction: row; 
    width: 100%;
    /* padding: 100px; */
    margin: 10px;
}
.is-viewer-question{
    width: auto;
    /* padding: 100px; */
    /* margin: 100px; */
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
}
.div-site-viewer-row{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}
.site-viewer{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}
.isSiteViewer{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: blueviolet; */
    border-style: solid;
}

.area-viewer-site-selection{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}

.area-viewer-area-selection{
    display: flex; 
    flex-direction: row; 
    width: 500px; 
    /* background-color: blue; */
}

.div-area-viewer-row{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}

.isAreaViewer{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: greenyellow; */
    border-style: solid;
}

.isLineViewer{
    width: auto;
    padding: 50px;
    /* margin: 100px; */
    /* background-color: indianred; */
    border-style: solid;
}

.isViewerContent{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}



.isOverviewer{
    display: flex; 
    flex-direction: row; 
    width: 100%;
    /* padding: 100px; */
    margin: 10px;
}
.is-overviewer-question{
    width: auto;
    /* padding: 100px; */
    /* margin: 100px; */
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
}
.div-line-overviewer-row{
    display: flex; 
    flex-direction: row; 
    width: 100%; 
}

.required{
    color: red;
    font-weight: bold;
}

/* .submitButton{
    background-color:greenyellow;
} */

button.submitButton{
    display:inline-block;
    padding:0.35em 1.2em;
    background-color:#3182ce;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:white;
    text-align:center;
    transition: all 0.2s;
}

button.submitButton:hover{
    color:#000000;
    background-color:#2d73b6;
}

@media all and (max-width:30em){
button.submitButton{
    display:block;
    margin:0.4em auto;
}
}

button.cancelButton{
    display:inline-block;
    padding:0.35em 1.2em;
    /* background-color: #e9dbdb; */
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:black;
    text-align:center;
    transition: all 0.2s;
}

button.cancelButton:hover{
    color:#000000;
    background-color:#beb2b2;
}

@media all and (max-width:30em){
button.cancelButton{
    display:block;
    margin:0.4em auto;
}
}

.a{
    background-color:greenyellow;
}